import "@js/app";
import "@css-pages/homepage";
import Slider from "@libs/slider/src/js/app.js";

const slider = Slider({
  root: document.querySelector("#banner"),
  autoplay: true,
  loop: true,
  delay: 3500,
  speed: 200,
});